import React, { useState } from "react";
import Button from "@mui/joy/Button";
import TextField from "@mui/material/TextField";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import DialogTitle from "@mui/joy/DialogTitle";
import DialogContent from "@mui/joy/DialogContent";
import Stack from "@mui/joy/Stack";
import Cancel from "@mui/icons-material/Cancel";
import Box from "@mui/material/Box";
import axios from "axios";

const Popup = ({ open, onClose }) => {
  const [formData, setFormData] = useState({
    FULLNAME: "",
    EMAIL: "",
    MOBILE: "",
    COMPANY: "",
    Message: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Function to handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "MOBILE") {
      const numericValue = value.replace(/\D/g, "");
      setFormData((prevData) => ({
        ...prevData,
        [name]: numericValue,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(
        "https://apilive.tagskills.com/api/contactinvayas",
        formData
      );
      if (response.status === 200) {
        alert(response?.data.Message);
        console.log(response);
        onClose();
      } else {
        setError("Unexpected response from the server.");
      }
    } catch (err) {
      setError("An error occurred while submitting the form.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog
        sx={{
          width: "90%",
          maxWidth: "600px",
          borderRadius: "12px",
          boxShadow: "0px 6px 24px rgba(0, 0, 0, 0.2)",
          padding: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 3,
          }}
        >
          <DialogTitle
            sx={{
              fontSize: "1.75rem",
              fontWeight: "bold",
              color: "#333",
              fontFamily: "'Josefin Sans', sans-serif",
            }}
          >
            Contact Us
          </DialogTitle>
          <Button
            variant="outlined"
            color="neutral"
            startDecorator={<Cancel />}
            onClick={onClose}
            sx={{
              border: "none",
              minWidth: "40px",
              minHeight: "40px",
            }}
          ></Button>
        </Box>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <TextField
                autoFocus
                required
                fullWidth
                name="FULLNAME"
                value={formData.FULLNAME}
                onChange={handleChange}
                placeholder="Enter Your Full Name"
                sx={{
                  fontFamily: "'Josefin Sans', sans-serif",
                  fontSize: "20px",
                  borderRadius: "8px",
                  padding: "12px",
                  backgroundColor: "#f9f9f9",
                  boxShadow: "inset 0px 1px 2px rgba(0, 0, 0, 0.1)",
                  "& .MuiInputBase-root": {
                    borderRadius: "8px",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(0, 0, 0, 0.23)",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#8C2970",
                  },
                  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#8C2970",
                  },
                }}
              />
              <TextField
                type="email"
                required
                fullWidth
                name="EMAIL"
                value={formData.EMAIL}
                onChange={handleChange}
                placeholder="Enter Your Email ID"
                sx={{
                  fontFamily: "'Josefin Sans', sans-serif",
                  borderRadius: "8px",
                  padding: "12px",
                  backgroundColor: "#f9f9f9",
                  boxShadow: "inset 0px 1px 2px rgba(0, 0, 0, 0.1)",
                  "& .MuiInputBase-root": {
                    borderRadius: "8px",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(0, 0, 0, 0.23)",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#8C2970",
                  },
                  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#8C2970",
                  },
                }}
              />
              <TextField
                type="tel"
                required
                fullWidth
                name="MOBILE"
                value={formData.MOBILE}
                onChange={handleChange}
                placeholder="Enter Your Mobile Number"
                inputProps={{ pattern: "[0-9]*", inputMode: "numeric" }} // Restrict to numeric input
                sx={{
                  fontFamily: "'Josefin Sans', sans-serif",
                  fontSize: "20px",
                  borderRadius: "8px",
                  padding: "12px",
                  backgroundColor: "#f9f9f9",
                  boxShadow: "inset 0px 1px 2px rgba(0, 0, 0, 0.1)",
                  "& .MuiInputBase-root": {
                    borderRadius: "8px",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(0, 0, 0, 0.23)",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#8C2970",
                  },
                  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#8C2970",
                  },
                }}
              />
              <TextField
                required
                fullWidth
                name="COMPANY"
                value={formData.COMPANY}
                onChange={handleChange}
                placeholder="Company Name(optional)"
                sx={{
                  fontFamily: "'Josefin Sans', sans-serif",
                  fontSize: "20px",
                  borderRadius: "8px",
                  padding: "12px",
                  backgroundColor: "#f9f9f9",
                  boxShadow: "inset 0px 1px 2px rgba(0, 0, 0, 0.1)",
                  "& .MuiInputBase-root": {
                    borderRadius: "8px",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(0, 0, 0, 0.23)",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#8C2970",
                  },
                  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#8C2970",
                  },
                }}
              />
              <Stack
                direction="row"
                spacing={1}
                justifyContent="flex-end"
                mt={3}
              >
                <Button
                  type="submit"
                  size="lg"
                  sx={{
                    fontFamily: "'Josefin Sans', sans-serif",
                    fontSize: "20px",
                    backgroundColor: "#8C2970",
                    color: "white",
                    borderRadius: "8px",
                    padding: "12px 24px",
                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
                    "&:hover": {
                      backgroundColor: "#6b1f54",
                    },
                  }}
                  disabled={loading}
                >
                  {loading ? "Submitting..." : "Submit"}
                </Button>
              </Stack>
              {error && (
                <Box sx={{ mt: 2, color: "red", fontSize: "0.875rem" }}>
                  {error}
                </Box>
              )}
            </Stack>
          </form>
        </DialogContent>
      </ModalDialog>
    </Modal>
  );
};

export default Popup;
