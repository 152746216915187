import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2", // Example primary color
    },
    secondary: {
      main: "#ff4081", // Example secondary color
    },
    text: {
      primary: "#122638", // Primary text color (used for titles)
      secondary: "#636A87", // Secondary text color (used for body text)
    },
    background: {
      default: "#ffffff", // Background color for the app
    },
  },
  typography: {
    fontFamily: "'Josefin Sans', sans-serif", // Your preferred font
    h3: {
      fontWeight: 700,
      lineHeight: "80px",
    },
    h4: {
      fontWeight: 700,
      lineHeight: "40px",
    },
    body1: {
      fontWeight: 500,
      lineHeight: "34px",
      color: "#636A87",
    },
    button: {
      textTransform: "none",
    },
  },
  spacing: 8,
  shape: {
    borderRadius: 8,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

export default theme;
