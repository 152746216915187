import React from "react";
import Grid from "@mui/material/Grid";
import { Typography, Box, Container, IconButton } from "@mui/material";
import { styled } from "@mui/system";
import SachinImage from "../../../src/assets/images/SachiFounder.png";
import PrashunImage from "../../assets/images/prashunshetty.png";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

// Styled components
const Team = styled(Box)(({ theme }) => ({
  width: "100%",
  backgroundColor: "#EFF0F4",
  borderRadius: "20px",
  padding: "30px",
  marginBottom: "20px",
}));

const TeamSection = styled(Grid)(({ theme }) => ({
  width: "100%",
  background: "linear-gradient(259.81deg, #FFFFFF 4.81%, #C4C4C4 158.44%)",
  borderRadius: "20px",
  padding: theme.spacing(6),
  margin: "30px auto",
}));

const ImageWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "450px",
  paddingTop: "80%", // 16:9 aspect ratio (adjust if needed)
  position: "relative",
  overflow: "hidden",
  borderRadius: "20px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
}));

const FounderImage = styled(Box)(({ imageUrl }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  background: `url(${imageUrl}) no-repeat`,
  backgroundSize: "cover",
  filter: "grayscale(100%)",
  transition: "filter 0.3s ease",
  "&:hover": {
    filter: "grayscale(0%)",
  },
}));

const FounderDetails = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  textAlign: "left",
  paddingLeft: theme.spacing(2),
}));

const FounderName = styled(Typography)({
  fontWeight: 700,
  fontSize: { md: "32px", xs: "20px", sm: "20px" },
  marginBottom: "8px",
});

const FounderRole = styled(Typography)({
  fontWeight: 500,
  fontSize: "20px",
  marginBottom: "16px",
});

const FounderBio = styled(Typography)({
  fontSize: "16px",
  marginBottom: "16px",
  color: "#636A87",
});

const SocialIcons = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "10px",
  marginTop: theme.spacing(2),
  color: "#8C2970;",
}));

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  color: "#8C2970",
}));

const OurTeam = () => {
  const linkedInUrlSachin =
    "https://www.linkedin.com/in/sachin-k-40799142?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app";
  const instagramUrlSachin =
    "https://www.instagram.com/iamsachinkar/profilecard/?igsh=MTVha2wzYmdsbGpmdg==";
  const instagramUrlPrashun =
    "https://www.instagram.com/prashunshetty_?igsh=MXVyZjYwc2ZyYW9laQ==";
  const linkedInUrlPrashun =
    "https://www.linkedin.com/in/prashun-shetty-41903a39?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app";

  return (
    <Container disableGutters style={{ marginTop: "40px" }}>
      <Typography
        variant="h4"
        gutterBottom
        align="left"
        color="#8C2970"
        sx={{
          fontSize: { md: "55px", xs: "32px", sm: "32px" },
          fontWeight: "bold",
          marginBottom: "24px",
          ml: { sm: "40px", xs: "40px", md: "10px" },
        }}
      >
        Our Team
      </Typography>
      <Team>
        <TeamSection container alignItems="center">
          {/* Founder 1 */}
          <Grid item xs={12} md={6}>
            <ImageWrapper>
              <FounderImage imageUrl={SachinImage} alt="Sachin Kocharekar" />
            </ImageWrapper>
          </Grid>
          <Grid item xs={12} md={6}>
            <FounderDetails>
              <FounderName>Sachin Kocharekar</FounderName>
              <FounderRole>Founder</FounderRole>
              <FounderBio>
                Sachin is a Senior Manager at an Indian MNC. He has been a
                developer and continues to write code every day. He has led the
                quality team since the beginning of Invayas.
              </FounderBio>
              <Typography variant="subtitle1" sx={{ fontSize: "20px" }}>
                Connect On:
              </Typography>
              <SocialIcons>
                <CustomIconButton
                  component="a"
                  href={instagramUrlSachin}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Instagram"
                >
                  <InstagramIcon />
                </CustomIconButton>
                <CustomIconButton
                  component="a"
                  href={linkedInUrlSachin}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="LinkedIn"
                >
                  <LinkedInIcon />
                </CustomIconButton>
              </SocialIcons>
            </FounderDetails>
          </Grid>
        </TeamSection>

        <TeamSection container alignItems="center">
          {/* Founder 2 */}
          <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
            <FounderDetails>
              <FounderName>Prashun Shetty</FounderName>
              <FounderRole>Co-founder</FounderRole>
              <FounderBio>
                Prashun Shetty is an Indian entrepreneur and businessman
                recognized for his significant contributions to the best virtual
                training center-Tagskills.
              </FounderBio>
              <Typography variant="subtitle1" sx={{ fontSize: "20px" }}>
                Connect On:
              </Typography>
              <SocialIcons>
                <CustomIconButton
                  component="a"
                  href={instagramUrlPrashun}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Instagram"
                >
                  <InstagramIcon />
                </CustomIconButton>
                <CustomIconButton
                  component="a"
                  href={linkedInUrlPrashun}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="LinkedIn"
                >
                  <LinkedInIcon />
                </CustomIconButton>
              </SocialIcons>
            </FounderDetails>
          </Grid>
          <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
            <ImageWrapper>
              <FounderImage imageUrl={PrashunImage} alt="Prashun Shetty" />
            </ImageWrapper>
          </Grid>
        </TeamSection>
      </Team>
    </Container>
  );
};

export default OurTeam;
