// src/components/WhyChooseUs/WhyChooseUs.js
import React, { useState } from "react";
import { Grid, Box, Typography, Card } from "@mui/material";
import { styled } from "@mui/material/styles";
import ButtonWithIcon from "../../components/common/Button/ ButtonWithIcon";
import Popup from "../../components/common/popup/Popup";

// Styled components
const StyledContainer = styled(Box)(({ theme }) => ({
  backgroundColor: "#EFF0F4",
  padding: theme.spacing(8),
 margin:"20px auto",
  borderRadius: "16px",
  maxWidth: "1400px",
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(4),
  },
}));

const StyledHeadingMain = styled(Typography)(({ theme }) => ({
  color: "#122638",
  fontSize: "55px",
  fontWeight: 700,
  marginBottom: theme.spacing(6),
  [theme.breakpoints.down("sm")]: {
    fontSize: "32px",
  },
  [theme.breakpoints.down("xs")]: {
    fontSize: "32px",
  },
}));

const StyledHeading = styled(Typography)(({ theme }) => ({
  color: "#8C2970",
  fontSize: "16px",
  fontWeight: 600,
  marginBottom: theme.spacing(3),
  [theme.breakpoints.down("sm")]: {
    fontSize: "16px",
    textAlign: "left",
  },
}));

const StyledBodyText = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  color: "#636A87",
  lineHeight: "1.8",
  marginBottom: theme.spacing(2),
  [theme.breakpoints.down("sm")]: {
    textAlign: "left",
  },
  [theme.breakpoints.down("xs")]: {
    textAlign: "left",
  },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  boxSizing: "border-box",
  width: "100%",
  maxWidth: "200px",
  padding: theme.spacing(2),
  background: "radial-gradient(circle, #EEEEEE 0%, #DEDFE4 100%)",
  border: "2px solid #FFFFFF",
  boxShadow: "0px 6px 10px 4px rgba(0, 0, 0, 0.15)",
  textAlign: "center",
  marginBottom: theme.spacing(2),
  [theme.breakpoints.down("sm")]: {
    maxWidth: "100%",
    height: "120px",
    padding: theme.spacing(2),
  },
}));

const CardHeading = styled(Typography)(({ theme }) => ({
  color: "#8C2970",
  fontFamily: "Josefin Sans",
  fontWeight: 700,
  fontSize: "50px",
  lineHeight: "1.2",
  [theme.breakpoints.down("sm")]: {
    fontSize: "2rem",
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: "#244078",
  fontSize: "20px",
}));

const WhyChooseUs = () => {
  const [popupOpen, setPopupOpen] = useState(false);

  const handleOpenPopup = () => {
    setPopupOpen(true);
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
  };

  return (
    <StyledContainer id="why-choose-us">
      <StyledHeadingMain variant="h4">Why Choose Us?</StyledHeadingMain>
      <Grid container spacing={20} alignItems="flex-start">
        {/* Left Section with Text */}
        <Grid item xs={12} md={6}>
          <Box mb={5}>
            <StyledHeading variant="h5">Innovation & Creativity</StyledHeading>
            <StyledBodyText variant="body1">
              Our team is never afraid to take up a new challenge and is always
              ready to help you out with our digital expertise & innovation.
            </StyledBodyText>
          </Box>
          <Box mb={5}>
            <StyledHeading variant="h5">High Quality</StyledHeading>
            <StyledBodyText variant="body1">
              We provide high-quality software development services that would
              enhance the growth of your business.
            </StyledBodyText>
          </Box>
          <Box mb={5}>
            <StyledHeading variant="h5">Peace of Mind</StyledHeading>
            <StyledBodyText variant="body1">
              We dedicate ourselves to work for your business to assure the
              smooth completion of projects, avoiding all unexpected hazards.
            </StyledBodyText>
          </Box>
        </Grid>

        {/* Right Section with Cards and Button */}
        <Grid item xs={12} md={6}>
          <Grid container spacing={2} mt={{ xs: "-150px", md: "0px" }}>
            <Grid item xs={12} sm={6}>
              <StyledCard>
                <CardHeading>10+</CardHeading>
                <StyledTypography variant="body2">Projects</StyledTypography>
              </StyledCard>
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledCard>
                <CardHeading>100+</CardHeading>
                <StyledTypography variant="body2">Clients</StyledTypography>
              </StyledCard>
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledCard>
                <CardHeading>5+</CardHeading>
                <StyledTypography variant="body2">Sectors</StyledTypography>
              </StyledCard>
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledCard>
                <CardHeading>3+</CardHeading>
                <StyledTypography variant="body2">Countries</StyledTypography>
              </StyledCard>
            </Grid>
          </Grid>
          <Box display="flex" mt={6}>
            <ButtonWithIcon
              text="Get in"
              subtext="Touch"
              iconColor="#122638"
              bgColor="#DEFC03"
              textColor="#122638"
              onClick={handleOpenPopup}
              fontSize="16px"
            />
          </Box>
        </Grid>
      </Grid>
      <Popup open={popupOpen} onClose={handleClosePopup} />
    </StyledContainer>
  );
};

export default WhyChooseUs;
