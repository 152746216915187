// src/components/WorkflowSection/WorkflowSection.js
import React, { useState } from "react";
import { Box, Typography, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import ButtonWithIcon from "../../common/Button/ ButtonWithIcon";
import Popup from "../../common/popup/Popup";

// Styled Components
const StyledContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "1400px",
  background: "#122638",
  border: "12px solid #C4C4C4",
  borderRadius: "25px",
  padding: theme.spacing(6),
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  margin: "20px auto",
  [theme.breakpoints.down("sm")]: {
    border: "8px solid #C4C4C4",
    padding: theme.spacing(4),
  },
}));

const LeftSection = styled(Box)(({ theme }) => ({
  width: "100%",
  marginBottom: theme.spacing(4),
  textAlign: "left",
  [theme.breakpoints.up("md")]: {
    width: "80%",
    textAlign: "left",
    marginRight: theme.spacing(4),
  },
}));

const RightSection = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(3),
  [theme.breakpoints.up("md")]: {
    width: "100%",
  },
}));

const StepBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  display: "flex",
  flexDirection: "column",
  backgroundColor: "#273A4A",
  border: "1px solid #636A87",
  borderRadius: "15px",
  boxSizing: "border-box",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    maxWidth: "none", // Allow full width for small screens
  },
}));

const StepHeading = styled(Typography)(({ theme }) => ({
  fontFamily: "Josefin Sans",
  fontWeight: 700,
  fontSize: "20px",
  lineHeight: "32px",
  color: "#DEFC03",
  marginBottom: theme.spacing(2),
  [theme.breakpoints.down("sm")]: {
    fontSize: "20px",
    lineHeight: "28px",
  },
}));

const StepBodyText = styled(Typography)(({ theme }) => ({
  fontFamily: "Josefin Sans",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "28px",
  color: "#FFFFFF",
  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
    lineHeight: "24px",
  },
}));

const WorkflowSection = () => {
  const [popupOpen, setPopupOpen] = useState(false);

  const handleOpenPopup = () => {
    setPopupOpen(true);
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
  };

  return (
    <StyledContainer>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <LeftSection>
            <Typography
              variant="h2"
              sx={{
                color: "#FFFFFF",
                fontSize: { sm: "32px", md: "55px", xs: "32px" },
                fontWeight: 700,
              }}
            >
              How do we
            </Typography>
            <Typography
              variant="h2"
              sx={{
                color: "#FFFFFF",
                fontSize: { sm: "32px", md: "55px", xs: "32px" },
                fontWeight: 700,
              }}
            >
              work to help you
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: "#FFFFFF",
                fontSize: "16px",
                lineHeight: "1.6",
                mt: "50px",
              }}
            >
              We’ve established a platform for budding entrepreneurs to master
              the art of initiating and expanding their businesses successfully.
            </Typography>
            <Box display="flex" mt={{ md: 14, xs: 5 }}>
              <ButtonWithIcon
                text="Get in"
                subtext="Touch"
                iconColor="#DEFC03"
                bgColor="#8C2970"
                color="#DEFC03"
                onClick={handleOpenPopup}
              />
            </Box>
          </LeftSection>
        </Grid>
        <Grid item xs={12} md={6}>
          <RightSection>
            <StepBox>
              <StepHeading>Research</StepHeading>
              <StepBodyText>
                To start the work, we research as needed.
              </StepBodyText>
            </StepBox>
            <StepBox>
              <StepHeading>Sketching</StepHeading>
              <StepBodyText>
                The design Process before excecution makes it clear.
              </StepBodyText>
            </StepBox>
            <StepBox>
              <StepHeading>Execution</StepHeading>
              <StepBodyText>The core stage of all optional cores.</StepBodyText>
            </StepBox>
            <StepBox>
              <StepHeading>Finishing</StepHeading>
              <StepBodyText>
                Ensure that the final stage produces perfection.
              </StepBodyText>
            </StepBox>
          </RightSection>
        </Grid>
      </Grid>
      <Popup open={popupOpen} onClose={handleClosePopup} />
    </StyledContainer>
  );
};

export default WorkflowSection;
