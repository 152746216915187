import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import InvayasLogo from "../../assets/images/logomain.png";

const StyledAppBar = styled(AppBar)(({ theme, bgcolor }) => ({
  backgroundColor: bgcolor || theme.palette.background.default,
  color: "#122638",
  boxShadow: "none",
  borderBottom: `5px solid ${theme.palette.grey[300]}`,
  padding: theme.spacing(2, 0),
  position: "sticky",
  width: "100%",
  top: 0,
  left: 0,
  zIndex: theme.zIndex.appBar,
  transition: "background-color 0.3s ease",
}));

const BackButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#8c2970",
  width: "180px",
  height: "60px",
  borderRadius: "30px",
  color: "#DEFC03",
  fontWeight: theme.typography.fontWeightBold,
  fontFamily: theme.typography.fontFamily,
  fontSize: "20px",
  "&:hover": {
    backgroundColor: "#8c2970",
  },
  marginLeft: theme.spacing(2),
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  color: "#122638",
  textDecoration: "none",
  [theme.breakpoints.up("md")]: {
    fontSize: "32px",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "30px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "24px",
  },
  [theme.breakpoints.down("xs")]: {
    fontSize: "20px",
  },
}));

const Logo = styled("img")(({ theme }) => ({
  width: "200px",
  height: "75px",
  [theme.breakpoints.down("md")]: {
    width: "200px",
    height: "75px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "150px",
    height: "70px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "150px",
    height: "70px",
  },
}));

function BackButtonNavbar() {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [bgColor, setBgColor] = useState("transparent");

  useEffect(() => {
    const handleScroll = () => {
      setBgColor(window.scrollY > 50 ? "#ffffff" : "transparent");
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleBack = () => {
    window.history.back();
  };

  return (
    <React.Fragment>
      <StyledAppBar position="fixed" bgcolor={bgColor}>
        <Toolbar sx={{ ml: "6vh", mr: "6vh" }}>
          <Box
            sx={{
              display: { xs: "flex", md: "none" },
              alignItems: "center",
              flexGrow: 1,
              justifyContent: "space-between",
            }}
          >
            <IconButton
              size="large"
              aria-label="menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>

            <Box sx={{ display: "flex", alignItems: "baseline", gap: "10px" }}>
              <Logo src={InvayasLogo} alt="logo" />
            </Box>
          </Box>

          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              alignItems: "baseline",
              gap: "10px",
              justifyContent: "center",
            }}
          >
            <Logo src={InvayasLogo} alt="logo" />
          </Box>

          <Box sx={{ flexGrow: 1 }} />

          <Box
            sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}
          >
            <BackButton onClick={handleBack}>BACK</BackButton>
          </Box>

          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
          >
            <MenuItem onClick={handleBack}>
              <Typography textAlign="center">BACK</Typography>
            </MenuItem>
          </Menu>
        </Toolbar>
      </StyledAppBar>
    </React.Fragment>
  );
}

export default BackButtonNavbar;
