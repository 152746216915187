import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

// Styled Container with responsive design
const StyledContainer = styled("div")(({ theme }) => ({
  maxWidth: "100%",
  textAlign: "left",

  // Apply padding for extra-small screens (xs)
  [theme.breakpoints.up("xs")]: {
    padding: theme.spacing(4),
  },

  // Apply padding for medium screens and up (md)
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(8),
  },
  background:
    "linear-gradient(79.97deg, #8C2970 -55.8%, #552857 -29.37%, #122638 75.6%)",
  boxSizing: "border-box",
}));

const HeadingTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Josefin Sans, sans-serif",
  fontStyle: "normal",
  fontWeight: 700,
  marginBottom: theme.spacing(4),
  color: "#FFFFFF",
  fontSize: "55pxpx",
  [theme.breakpoints.up("xs")]: {
    fontSize: "32px",
    marginBottom: theme.spacing(1),
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "32px",
  },

  [theme.breakpoints.up("md")]: {
    fontSize: "55px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "55px",
  },
}));

// Section Typography with responsive font size
const SectionTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Josefin Sans, sans-serif",
  fontStyle: "normal",
  fontWeight: 700,
  color: "#DEFC03",
  fontSize: "24px",
  lineHeight: "32px",
  marginBottom: theme.spacing(2),
  [theme.breakpoints.up("sm")]: {
    fontSize: "28px",
    lineHeight: "36px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "32px",
    lineHeight: "40px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "32px",
    lineHeight: "44px",
  },
}));

// Body Typography with responsive font size
const BodyTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Josefin Sans, sans-serif",
  fontStyle: "normal",
  fontWeight: 400,
  color: "#FFFFFF",
  fontSize: "16px",
  lineHeight: "24px",
  [theme.breakpoints.up("sm")]: {
    fontSize: "16px",
    lineHeight: "26px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "16px",
    lineHeight: "28px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "16px",
    lineHeight: "30px",
  },
}));

const WorkTogether = () => {
  return (
    <StyledContainer>
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12}>
          <HeadingTypography variant="h4">
            Work optimally for satisfaction
          </HeadingTypography>
        </Grid>

        {[
          {
            title: "Emotional Intelligence",
            body: "Over a decade has taught us to not just listen; we search for meaning in what people say to identify ways to have a positive impact on people’s lives.",
          },
          {
            title: "Enquiry Assistance",
            body: "You come to us with your project; we provide all the technical consultation, all the planning, and full costing of the project.",
          },
          {
            title: "Project Consultation",
            body: "We are always available on call to provide all assistance for your inquiries and help you in scheduling appointments.",
          },
          {
            title: "We Deliver Excellence",
            body: "We always update ourselves from time to time with the advanced technologies so that we deliver the best applications on time.",
          },
        ].map(({ title, body }, index) => (
          <Grid item xs={12} md={3} key={index}>
            <SectionTypography variant="h6" sx={{ width: "60%" }}>
              {title}
            </SectionTypography>
            <BodyTypography variant="body1">{body}</BodyTypography>
          </Grid>
        ))}
      </Grid>
    </StyledContainer>
  );
};

export default WorkTogether;
