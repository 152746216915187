// src/components/common/ButtonWithIcon.js
import React from "react";
import { Button, Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";

const StyledButton = styled(Button)(({ theme, bgColor, textColor }) => ({
  width: "100%",
  maxWidth: "500px",
  height: "60px",
  backgroundColor: bgColor || "#DEFC03",
  borderRadius: "50px",
  fontFamily: "Josefin Sans",
  fontSize: "1.125rem ",
  fontWeight: 600,
  color: textColor || "#122638",
  transition: "background-color 0.3s ease",
  "&:hover": {
    backgroundColor: "#FFC300",
  },
  [theme.breakpoints.down("sm")]: {
    height: "50px",
    fontSize: "1rem",
  },
}));

const StyledButtonText = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
}));

const ButtonWithIcon = ({
  text,
  subtext,
  iconColor,
  bgColor,
  textColor,
  onClick,
  color,
}) => {
  return (
    <StyledButton
      variant="contained"
      bgColor={bgColor}
      textColor={textColor}
      onClick={onClick}
      endIcon={
        <Box display="flex" alignItems="center">
          <ChevronRightIcon sx={{ color: iconColor }} />
          <ArrowForwardIosIcon sx={{ color: iconColor }} />
        </Box>
      }
    >
      <StyledButtonText>
        <Typography
          display="flex"
          flexDirection="column"
          alignItems="center"
          color={color}
        >
          {text}
          <Box display="flex" alignItems="center">
            <HorizontalRuleIcon sx={{ ml: 1, mr: 1, color: iconColor }} />{" "}
            {subtext}
          </Box>
        </Typography>
      </StyledButtonText>
    </StyledButton>
  );
};

export default ButtonWithIcon;
