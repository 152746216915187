import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { styled, keyframes } from "@mui/material/styles";
import BrandingWatermarkIcon from "@mui/icons-material/BrandingWatermark";
import DesktopWindowsIcon from "@mui/icons-material/DesktopWindows";
import MobileFriendlyIcon from "@mui/icons-material/MobileFriendly";
import WebIcon from "@mui/icons-material/Web";
import SchoolIcon from "@mui/icons-material/School";
import CampaignIcon from "@mui/icons-material/Campaign";

// Keyframe for subtle text color animation
const textColorAnimation = keyframes`
  0% { color: #122638; }
  50% { color: #FF4081; }
  100% { color: #122638; }
`;

const StyledServiceCard = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "100%",
  maxWidth: "450px",
  height: "280px",
  overflow: "hidden",
  padding: theme.spacing(3),
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  transition: "transform 0.3s ease, box-shadow 0.3s ease",
  boxShadow: `0px 4px 15px rgba(0, 0, 0, 0.1)`,
  borderRadius: "30px",
  zIndex: 1,

  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: -1,
    borderRadius: "30px",
    padding: "5px",
    background: "linear-gradient(to right, #DEFC03, #8C2970)",
    WebkitMask:
      "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
    WebkitMaskComposite: "destination-out",
    maskComposite: "exclude",
  },

  // Add border radius to the border styling
  "&:before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    borderRadius: "15px",
    border: "2px solid",
    borderImage: "linear-gradient(to right, #DEFC03, #8C2970) 1", // Gradient border on hover
    zIndex: -1,
    transition: "border-color 0.3s ease",
  },

  "&:hover": {
    transform: "scale(1.05)",
    boxShadow: `0px 10px 25px rgba(0, 0, 0, 0.2)`,
    "&:before": {
      borderColor: "rgba(255, 205, 0, 0.6)", // Adjust color on hover
    },
  },

  [theme.breakpoints.down("sm")]: {
    maxWidth: "100%",
    height: "auto",
  },
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  fontSize: "60px",
  color: "#122638",
  marginBottom: theme.spacing(2),
  animation: `${textColorAnimation} 5s infinite`,
}));

const CardTitle = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
  fontWeight: 700,
  marginBottom: theme.spacing(1),
  color: "#122638",
  textAlign: "left",
  animation: `${textColorAnimation} 5s infinite`,
}));

const CardDescription = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  lineHeight: "1.5",
  color: "#636A87",
  textAlign: "left",
}));

const Services = () => {
  return (
    <>
      <Box
        sx={{
          mt: "30px",
          textAlign: { md: "left", xs: "left" },
          m: { md: "8vh", xs: "4vh" },
        }}
      >
        <Typography
          variant="h2"
          sx={{
            color: "#122638",
            fontWeight: 700,
            fontSize: { md: "55px", xs: "32px", sm: "32px" },
          }}
        >
          Services
        </Typography>
        <Typography
          variant="body1"
          sx={{ fontSize: { md: "16px", xs: "16px" } }}
        >
          Software Development services cover all stages of the application life
          cycle from requirements through design, test, and implementation, all
          customized.
        </Typography>
      </Box>
      <Box
        sx={{
          padding: "50px",
          backgroundColor: "#F5F5F5",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <StyledServiceCard>
              <IconWrapper>
                <BrandingWatermarkIcon fontSize="inherit" />
              </IconWrapper>
              <CardTitle>Brand Identity</CardTitle>
              <CardDescription>
                We create unique and powerful brand identities that help
                companies achieve their goals and stand out amongst the
                competition.
              </CardDescription>
            </StyledServiceCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <StyledServiceCard>
              <IconWrapper>
                <DesktopWindowsIcon fontSize="inherit" />
              </IconWrapper>
              <CardTitle>Web & Desktop Application</CardTitle>
              <CardDescription>
                We design, develop, and optimize websites and desktop
                applications, which also includes maintenance.
              </CardDescription>
            </StyledServiceCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <StyledServiceCard>
              <IconWrapper>
                <MobileFriendlyIcon fontSize="inherit" />
              </IconWrapper>
              <CardTitle>Mobile Application</CardTitle>
              <CardDescription>
                Our applications are creative, user-friendly, highly functional,
                easy to maintain, and responsive for Android and iOS mobile
                devices.
              </CardDescription>
            </StyledServiceCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <StyledServiceCard>
              <IconWrapper>
                <WebIcon fontSize="inherit" />
              </IconWrapper>
              <CardTitle>Website Design</CardTitle>
              <CardDescription>
                We help companies craft captivating websites. Our designs tell
                stories and guide users, from vision to action.
              </CardDescription>
            </StyledServiceCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <StyledServiceCard>
              <IconWrapper>
                <SchoolIcon fontSize="inherit" />
              </IconWrapper>
              <CardTitle>SAP Projects</CardTitle>
              <CardDescription>
                We provide the functionality to support your processes, develop
                custom code to integrate apps with SAP systems, and create
                reports, interfaces, and programs.
              </CardDescription>
            </StyledServiceCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <StyledServiceCard>
              <IconWrapper>
                <CampaignIcon fontSize="inherit" />
              </IconWrapper>
              <CardTitle>Influence Marketing</CardTitle>
              <CardDescription>
                We connect you with creators who match your brand identity,
                orchestrating creative campaigns across social media to ensure
                maximum impact for your target audience.
              </CardDescription>
            </StyledServiceCard>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Services;
